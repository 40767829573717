/* DNA Painter © 2017-2018 Jonathan Perl */

$(function() {
   // var t0 = performance.now();
      var delay = (function() {
        var timer = 0;
        return function(callback, ms) {
          clearTimeout(timer);
          timer = setTimeout(callback, ms);
        };
      })();
    function isIE() {
        // IE 10 and IE 11
        return /Trident\/|MSIE/.test(window.navigator.userAgent);
    }

    function showBrowserAlert() {
    if (document.querySelector('.unsupported-browser')) {
        var d = document.getElementsByClassName('unsupported-browser');

        is_IE = isIE();

        if (is_IE) {
            d[0].innerHTML = 'You are using an <strong>outdated</strong> browser. This site might not work as expected. <br />Please <a href="http://browsehappy.com/">upgrade your browser</a> to improve your experience.';
            d[0].style.display = 'block';
        }
    }
    };

    showBrowserAlert();


    if($("body").hasClass('page-tools')){
        $(".tools-area > div").on('click', function(){
            var mylink = $(this).find('a').first().attr('href');
            window.location = mylink;
        });
    }

    if($("body").hasClass('page-account-settings')){
        $("#cancel-paypal").on('click', function(e){
            e.preventDefault();
            // show confirm
            // hide main
            $(".cancel-paypal-container").css('display','none');    
            $(".confirm-cancel-paypal-container").css('display','block');
        });


         $("#renew-paypal-confirm").on('click', function(e){
            // could just make this a regular submit
            e.preventDefault();
             $.ajax({
                    type: "GET",
                    url: "/api/ppupdate/renew",
                    success: function(result) {
                        //window.Location = '/account/settings';
                        window.location.reload();                    
                    },
                    error: function(exception) {
                        $("#pp_error").css('display', 'block');
                    }
                });

            // do an ajax call to cancellation script
            // on success, show updated status
            // hide confirm
            // possibly make it so they can reactivate (if I use 'suspend')
            
        });      

        $("#cancel-paypal-confirm").on('click', function(e){
            // could just make this a regular submit
            e.preventDefault();
             $.ajax({
                    type: "GET",
                    url: "/api/ppupdate/cancel",
                    success: function(result) {
                        //window.Location = '/account/settings';
                        window.location.reload();                    
                    },
                    error: function(exception) {                        
                        $("#pp_error").css('display', 'block');
                    }
                });

            // do an ajax call to cancellation script
            // on success, show updated status
            // hide confirm
            // possibly make it so they can reactivate (if I use 'suspend')
            
        });        


        $("#cancel-paypal-cancel").on('click', function(e){
            e.preventDefault();

            // show main
            // hide confirm

          $(".cancel-paypal-container").css('display','block');    
          $(".confirm-cancel-paypal-container").css('display','none');
           
        });  
        


    }


    

    if($("body").hasClass('home-new') || $("body").hasClass('page-mailing-faq')){
        $(".hcard").on('click', function(e){if(e.target.nodeName=='DIV' ||1==1){
            var dest = $(this).attr('data-uri');
            if(dest){
            window.location =dest;
            }
        }});
        //  if((e.target).nodeName !='A'){

        $("#open-mailerform").on('click', function(e){
            openMailerForm(e); 
            return false;
        });
        $(".floater").on("click", ".dpclose", function(e) {
            e.preventDefault();
            closePopups();
            $(this).parent().hide();
            $("body").removeClass('form-toggled');
        });
        $(document).keyup(function(e) {
            if (e.which == 27) {
                $("#confirm-delete-profile").css('display','none');       
                $("#mailerform").css('display','none');
                $("body").removeClass('form-toggled');
            }
        });

    }


    if($("body").hasClass('home-new') ){
            var RSS_URL = 'https://dnapainter.com/blog/feed/';

        $.ajax(RSS_URL, {
          accepts: {
            xml: "application/rss+xml"
          },

          dataType: "xml",

          success: function(data) {
            var count = 0;
            $(data)
              .find("item")
              .each(function() {
                count++;
                if(count >4){  
                    return false;    
                }
                var el = $(this);
                var de = el.find('description');
                var mydesc = new DOMParser().parseFromString(de[0].innerHTML, 'text/html');
            var im = '';
               
                im = mydesc.body.firstChild.getAttribute('src');

                var template = '<div class="col-sm-3 col-xs-6"><a href="'+el.find("link").text()+'" target="_blank" rel="noopener"><img src="'+im+'" alt=""></a><a class="btitle" href="'+el.find("link").text()+'" target="_blank" rel="noopener">'+el.find("title").text()+'</a></div>';

                document.getElementById('recent-blog-posts').insertAdjacentHTML("beforeend", template);

              });
          }
        });        
}

    if($("body").hasClass('home-loggedin')){
        $(".tablesorter").tablesorter({
        sortList: [
            [3, 1],
        ]
        });
        updateBasedOnHash();

        window.onhashchange = function() {
          updateBasedOnHash();
        }

        $(".tooltabs button").on('click', function(e){
            $(".tooltabs button").removeClass('selectedtab');
            $(this).addClass('selectedtab');
            var thistab = $(this).attr('data-tab');
            $(".tab-content").css('display','none');
            $("#"+thistab).css('display','block');
        });

        $(".litabs li").on('click', function(e){
            var hashstring = '';
            if((e.target).nodeName !='A'){
                var tg = $(this).find('a');
                tg.trigger('click');

                hashstring = $(tg).attr('href');
            }
            else {
                hashstring = $(e.target).attr('href');

            }
            window.location.hash = hashstring.replace(/#list-/,'');
        });
        
        $(".hfilterdata").on("keyup", function(e){
            var currentval = $(this).val();
            var targettable = $(this).attr('data-table');

             delay(function() {    
                filterDataTable(currentval, targettable);
                }, 500);         
        });
      

        $(".cancel-hfilter").on("click", function(e){
            $(this).prev().val('');
            var targettable = $(this).attr('data-table');            
            filterDataTable('', targettable);
        });
        // if a # is set, use it for a click event
        



        function updateBasedOnHash(){
            if(window.location.hash) {
                setTimeout(function(){
                    if(window.location.hash=='#watotrees'){
                        $("div.feat-tools").trigger('click');
                        $("ul.tooltabs .wato3tab").trigger('click');
                    }
                    else if(window.location.hash=='#trees'){
                        $("div.feat-trees").trigger('click');
                    }            
                    else if(window.location.hash=='#profiles'){
                        $("div.feat-profs").trigger('click');
                    } 
                },10);
            }
            else {
                var loc = ($(".feature-row #aloaded > div").attr('class')).replace('homefeat ','');
                $("div."+loc).trigger('click');
            }
        }


    }
    if(window.location.hash=='#getsegfile'){
        setTimeout(function(){$("#importinst").trigger('click');},10);
        
    }


    if($("body").hasClass('page-profiles') || $("body").hasClass('page-trees') || $("body").hasClass('page-tools')  || $("body").hasClass('home-loggedin')){
        // show 'need help'
        //console.log($('.profiles-list li').length);
        if($('.profiles-list li').length==1){
            var msegs = $('.profiles-list li').first().find('span').text();
            if(typeof(msegs)!=='undefined' && msegs==0) {
                $("#need-help").css('display', 'block');
            }
            //console.log($('.profiles-list li').first().find('span').text());

        }
         $(".cancel-filter-profiles").on("click", function(e){
                $("#filterprofiles").val('');
                filterProfiles('');
        });  

    // * click on delete profile icon
    $(".del-profile").on("click", function(e) {
        e.preventDefault();
        //console.log('hello!');
        $("#bubuttons, #offer-backup").css('display','block');
        $("#masterdelbuttons").css('display','none');
        var delprofiletext = $.trim($(this).parent().parent().find('.htlink').html());
        delprofiletext = delprofiletext.replace('<br>',' - ');
        delprofiletext = delprofiletext.replace('<br>','');

        $(".del-profile-name").html(delprofiletext);
        //console.log($(".del-profile-name").text($.trim($(this).parent().parent().find('.htlink').innerHTML)
        $(".del-profile-confirm").attr('data-id', $(this).attr('data-id'));
        //$('body').addClass('form-toggled');   
        if($(this).attr('data-type')){
            var thisdatatype = $(this).attr('data-type');
            $("#delwato").css('display','none');
            var thisdatatypename = thisdatatype;
            if(thisdatatype=='probtree'){
              thisdatatypename = 'probability tree';  
            }
            if(thisdatatype=='profile'){
              thisdatatypename = 'chromosome map';  
            }
            if(thisdatatype=='tree'){
              thisdatatypename = 'ancestral tree';  
            }                        
            if(thisdatatype=='watotree'){
              thisdatatypename = 'WATO plus tree'; 
              $("#delwato").css('display','inline');
 
            }            
            if(thisdatatype=='coveragetree'){
              thisdatatypename = 'coverage tree';  
            }           
            $("#makebackup").attr('href','/b/'+thisdatatype+'/'+ $(this).attr('data-id'));
        }
        $("#confirm-delete-profile").show();
          var moffset = ($("#confirm-delete-profile").offset().top);
          if(typeof(thisdatatype)!=='undefined'){
            $("#masterdel").attr('data-type',thisdatatype);
            $("#del-data-type").text(thisdatatypename);            
          }
         
            $('body, html').animate({
                scrollTop: (moffset-20)
            }, 600);
    });
 // * no backup needed, bring up confirm buttons
    $("#confirm-delete-profile").on("click", ".del-profile-proceed", function(e) {
        $("#bubuttons, #offer-backup").css('display','none');
        $("#masterdelbuttons").css('display','block');

    });

   


    // * cancel deleting of profile
    $("#confirm-delete-profile").on("click", ".del-profile-cancel", function(e) {
        $("#confirm-delete-profile").hide();
        $('body').removeClass('form-toggled');

    });




      $("#makebackup").on("click", function(e) {
        if(checkLoggedIn()===false){
            e.preventDefault();
            return false;
        }        
        });



    // * confirm deleting of profile
    $(".del-profile-confirm").on("click", function(e) {
        $("#confirm-delete-profile").hide();
        var thisprof = $(this).attr('data-id');
        var thistype = $(this).attr('data-type');
        //console.log(thistype);
        //console.log(thisprof);
        if(thistype=='probtree'){
            deleteProbtree(thisprof);
        }
        else if(thistype=='watotree'){
            deleteWatotree(thisprof);
        }        
        else if(thistype=='coveragetree'){
            deleteCoveragetree(thisprof);
        }        
        else if(thistype=='tree'){
            deleteTree(thisprof);
        }        
        else {
            deleteProfile(thisprof);
            window.localStorage.removeItem('dp'+thisprof+'cachedupdated');
            window.localStorage.removeItem('dp'+thisprof+'cachedGroups');
            window.localStorage.removeItem('dp'+thisprof+'cachedMatches');
        }

        $('body').removeClass('form-toggled');
    });  

    
    }

    if($("body").hasClass('page-faq') || $("body").hasClass('page-trees-faq')  || $("body").hasClass('page-wato-faq')){
        // check location hash
        // if something in there, $(this).next().collapse('show');
        //console.log('yo');
        if(window.location.hash) {            
          if(typeof($(window.location.hash)!=='undefined')){
            $(window.location.hash).next().collapse('show');
            var moffset = ($(window.location.hash).offset().top);
            $('body, html').animate({
                scrollTop: (moffset-20)
            }, 1000);

            }                     // Fragment exists
        } 
    }

    // general items

    $("#searchsite").on('click', function(){
      doSearch();
    });

    $('.collapser').click(function(e) {        
        e.preventDefault();
        toggleCollapser($(this));
    });



        var youtube = document.querySelectorAll( ".youtube" );
        
        for (var i = 0; i < youtube.length; i++) {
            //var source = "https://img.youtube.com/vi/"+ youtube[i].dataset.embed +"/sddefault.jpg";
            //var source = "https://img.youtube.com/vi/"+ youtube[i].getAttribute('data-embed') +"/sddefault.jpg";
            var source = '';
            if(youtube[i].hasAttribute('data-pic')){
                var source = youtube[i].getAttribute('data-pic');
            }   
            if(source==''){
                source = "https://img.youtube.com/vi/"+ youtube[i].getAttribute('data-embed') +"/mqdefault.jpg";
            }
                var image = new Image();
                image.src = source;
                image.addEventListener( "load", function() {
                    youtube[ i ].appendChild( image );
                }( i ) );
                /*
                var image = new Image();
                image.src = source;
                image.addEventListener( "load", function() {
                    //youtube[ i ].appendChild( image );
                    youtube[ i ].insertBefore( image, youtube[ i ].firstChild );
                }( i ) );
                */
                youtube[i].addEventListener( "click", function() {

                    var iframe = document.createElement( "iframe" );

                            iframe.setAttribute( "frameborder", "0" );
                            iframe.setAttribute( "allow", "autoplay" );
                            iframe.setAttribute( "allowfullscreen", "" );
                            iframe.setAttribute( "src", "https://www.youtube.com/embed/"+ this.getAttribute('data-embed') +"?rel=0&showinfo=0&autoplay=1" );

                            this.innerHTML = "";
                            this.appendChild( iframe );
                } );    
        };



    $(".expand-all").on("click", function(e) {
        //$(".answer").collapse('toggle');
        if($(this).text()=='Expand all'){
            $(this).text("Collapse all")
            $(".answer").collapse("show");

        }
        else{
            $(this).text("Expand all")
            $(".answer").collapse("hide");

        }
    });
    
    $("#filterprofiles").on("keyup", function(e){
        var currentval = $("#filterprofiles").val();
         setTimeout(function() {    
            var currentval = $("#filterprofiles").val();           
            filterProfiles(currentval);
            }, 500);         
    }); 

});  // end of the doc ready

function doSearch(){
      var term = $("#sterm").val();
      window.location = 'https://duckduckgo.com/?q=site%3Adnapainter.com+'+encodeURIComponent(term);
}

function deleteProbtree(id){
      $.ajax({
        type: "GET",
        url: "/api/tools/wato/pt/clear/" + id,
        success: function(result) {
            $(".probtree" + id).fadeOut(300, function(){$(".probtree" + id).remove();});
        },
        error: function(exception) {}
    });
}

function deleteWatotree(id){
      $.ajax({
        type: "GET",
        url: "/api/tools/wato3/pt/clear/" + id,
        success: function(result) {
            $(".watotree" + id).fadeOut(300, function(){$(".watotree" + id).remove();});
        },
        error: function(exception) {}
    });
}



function deleteCoveragetree(id){
      $.ajax({
        type: "GET",
        url: "/api/tools/coverage/ct/clear/" + id,
        success: function(result) {
            $(".coveragetree" + id).fadeOut(300, function(){$(".coveragetree" + id).remove();});
        },
        error: function(exception) {}
    });
}

function toggleCollapser(elem){
        $(elem).next().collapse('toggle');
        $(elem).find('span').toggleClass('glyphicon-minus', 'glyphicon-plus');

}

function deleteTree(id) {
    // if this map belongs to the person logged in, delete it.
    // $.get('/api/maps/p/clear/'+id);
    $.ajax({
        type: "GET",
        url: "/api/trees/t/clear/" + id,
        success: function(result) {
            $(".tree" + id).fadeOut(300, function(){$(".tree" + id).remove();});
        },
        error: function(exception) {}
    });
}

function exportTableToCSV($table, filename) {
    //console.log(filename);
    var $rows = $table.find('tr:visible:has(td,th)'),

        // Temporary delimiter characters unlikely to be typed by keyboard
        // This is to avoid accidentally splitting the actual contents
        tmpColDelim = String.fromCharCode(11), // vertical tab character
        tmpRowDelim = String.fromCharCode(0), // null character

        // actual delimiter characters for CSV format
        colDelim = '","',
        rowDelim = '"\r\n"',

        // Grab text from table into CSV formatted string
        csv = '"' + $rows.map(function(i, row) {
            var $row = $(row),
                $cols = $row.find('td:not(.chkcol),th:not(.chkcol)');

            return $cols.map(function(j, col) {
                var $col = $(col),
                    text = $col.text();

                return text.replace(/"/g, '""'); // escape double quotes

            }).get().join(tmpColDelim);

        }).get().join(tmpRowDelim)
        .split(tmpRowDelim).join(rowDelim)
        .split(tmpColDelim).join(colDelim) + '"';


        // OK this is the place
        // I have csv and I have to tweak it to put the group notes in somehow
        //console.log(csv);
    // Deliberate 'false', see comment below
    if (window.navigator.msSaveBlob) {

        var blob = new Blob([decodeURIComponent(csv)], {
            type: 'text/csv;charset=utf8'
        });

        // Crashes in IE 10, IE 11 and Microsoft Edge
        // See MS Edge Issue #10396033
        // Hence, the deliberate 'false'
        // This is here just for completeness
        // Remove the 'false' at your own risk
        window.navigator.msSaveBlob(blob, filename);

    } else if (window.Blob && window.URL) {
        // HTML5 Blob  
        var blob = new Blob([csv], {
            type: 'text/csv;charset=utf-8'
        });
        var csvUrl = URL.createObjectURL(blob);
        $(this)
            .attr({
                'download': filename,
                'href': csvUrl
            });
    } else {
        // Data URI
        var csvData = 'data:application/csv;charset=utf-8,' + encodeURIComponent(csv);

        $(this)
            .attr({
                'download': filename,
                'href': csvData,
                'target': '_blank'
            });
    }
}



function deleteProfile(id) {
    // if this profile belongs to the person logged in, delete it.
    // $.get('/api/profiles/p/clear/'+id);
    $.ajax({
        type: "GET",
        url: "/api/profiles/p/clear/" + id,
        success: function(result) {
            $(".profile" + id).fadeOut(300, function(){$(".profile" + id).remove();});
        },
        error: function(exception) {}
    });
}

function closePopups(){
   // console.log('called');
   // console.log($(".popup"));
    //console.log('remove popped!');
    //console.log($(".cp strong.popped"));
    //setTimeout(function(){
        var clones = $(".cp").find('.clone');
       // console.log('removing '+clones.length+' clones');
       // console.log(clones);
        $(clones).remove();
    //},1);
    
    $(".popup").remove();
    $(".cp strong.popped, .cp div.popped").removeClass('popped');
}

function initialiseColorPicker(elem, currentcolor) {
    // todo - print recent colours in here (maybe at the bottom and can explain on the page)

    if(currentcolor=='random'){
        currentcolor = getNewColor();
    }
    $("#" + elem).val(currentcolor);

    $("#" + elem).spectrum({
        color: currentcolor,
        showInput: true,
        className: "full-spectrum",
        showInitial: true,
        showPalette: true,
        showSelectionPalette: true,
        maxSelectionSize: 10,
        preferredFormat: "hex",
        localStorageKey: "spectrum.dnap",
        move: function(color) {

        },
        show: function() {

        },
        beforeShow: function() {

        },
        hide: function() {

        },
        change: function() {

        },
        palette: [
["#F7F79F","#E9B55C","#EAB7BC","#ECAADB"," #B8B1CA"," #BEDDF4"," #C0E0E1","#BDDD55"],
["#F4EA24","#C87916","#D2685C","#B14989"," #635284"," #4873A0"," #699899","#7BA614"],
["#EABE1C","#934D07","#73160C","#550543"," #3D2550"," #0E1E50"," #2A4D4A","#466707"],
["#fff700","#c26225","#f36993","#d13076","#5d0e9f","#0316c1","#09585d","#88b14b"],
["#e4f200","#ff6000","#fe0000","#f36993","#9400ff","#00d1e5","#03d1ab","#006e51"]
        ]
    });
}

function getNewColor(profile, existing) {
    var colorSet = randomColor({count:15});
    if(typeof(existing)==='undefined'){
        var existing = getExistingColours(profile);
    }
   // console.log(existing);
    var masterArray = [];
  //  console.log(existing);
    for (var i in colorSet){
        var simFactor = [];
        // convert hex to rgb
        var rgb_new = hexToRgb(colorSet[i])
        // convert rgb to xyz
        //var xyz_new = rgbToXYZ(rgb_new['r'], rgb_new['g'],rgb_new['b']);
        var xyz_new= convert_RGBA_XYZ(rgb_new);

        // convert xyz to L*A*B
        var lab_new = xyzToLab(xyz_new.X,xyz_new.Y,xyz_new.Z);


        // get the similarity percentage for this colour and each existing one
        for( var c in existing){
            var rgb_e = hexToRgb(existing[c]); 
            var xyz_e= convert_RGBA_XYZ(rgb_e);


            var lab_e = xyzToLab(xyz_e.X,xyz_e.Y,xyz_e.Z);
            simFactor.push(DeltaE.getDeltaE00(lab_new, lab_e));
        }
        masterArray.push(Math.min.apply(null, simFactor));
        // store the lowest value (ie the most similar this is to any existing colour) in an array
    }
    // choose the one that's the least similar to any other colour
    var theone = Math.max.apply(null,masterArray);
    var theindex = masterArray.indexOf(theone);
    return colorSet[theindex];

    //return randomColor();
}

function convert_RGBA_XYZ( rgb )
    {
        var temp = [];
        var keys = ['r','g','b'];
        for ( var i in keys) {
            var mykey = keys[i];
            if ( rgb[mykey] <= 0.04045 ) {
                temp[i] = rgb[mykey] / 12.92;
            } else {
                temp[i] = Math.pow( (rgb[mykey]+0.055)/1.055, 2.4 );
            }
        }

       var X = 0.4124*temp[0]+0.3576*temp[1]+0.1805*temp[2];
       var Y = 0.2126*temp[0]+0.7152*temp[1]+0.0722*temp[2];
       var Z = 0.0193*temp[0]+0.1192*temp[1]+0.9505*temp[2];
           return {'X':X,'Y':Y,'Z':Z};
    }


function xyzToLab(x, y, z){ //a convertor from xyz to lab model

 var x2 = x/95.047;
 var y2 = y/100;
 var z2 = z/108.883;
 if(x2>0.008856){
      x2 = Math.pow(x2,1/3);
 }
 else{
      x2 = (7.787*x2) + (16/116);
 }
 if(y2>0.008856){
      y2 = Math.pow(y2,1/3);
 }
 else{
      y2 = (7.787*y2) + (16/116);
 }
 if(z2>0.008856){
      z2 = Math.pow(z2,1/3);
 }
 else{
      z2 = (7.787*z2) + (16/116);
 }
 var l= 116*y2 - 16;
 var a= 500*(x2-y2);
 var b= 200*(y2-z2);
 var labresult = {'L': l,'A': a,'B': b };

 return(labresult);
}

function hexToRgb(hex) {
   hex = hex.replace(/[^0-9A-F]/gi, '');
    var bigint = parseInt(hex, 16);
    var r = (bigint >> 16) & 255;
    var g = (bigint >> 8) & 255;
    var b = bigint & 255;
    return {r: r, g: g, b: b};
}

function getFieldValue(id) {
   // console.log(currentprofile + "cached" + id);
    if(typeof(this.currentprofile)==='undefined'){
        if(typeof(this.currentmap)!=='undefined'){
            var saveid = this.currentmap;
        }
        else {
            var saveid = '';
        }
    }
    else {
        var saveid = this.currentprofile;
    }    
    return window.localStorage.getItem(saveid + "cached" + id);
}

function saveFieldLocally(id, value) {
    
    if(typeof(this.currentprofile)==='undefined'){
        // added so can use in tree
        if(typeof(this.currentmap)!=='undefined'){
            var saveid = this.currentmap;
        }
        else {
            var saveid = '';
        }
    }
    else {
        var saveid = this.currentprofile;
    }
    if (supportsLocalStorage()) {
        window.localStorage.setItem(saveid + "cached" + id, value);
    }
}

function supportsLocalStorage() {
    var testKey = 'test',
        storage = window.localStorage;
    try {
        storage.setItem(testKey, '1');
        storage.removeItem(testKey);
        return true;
    } catch (error) {
        return false;
    }
}

function escape_quotes(Text, display){
    var t1 = Text.replace(/'/g,'’');
    t1 = t1.replace(/"/g,'');
    return t1;
    
    /*if(typeof(display)==='undefined'){
        return t1.replace('"','&quot;');
    }
    return t1;*/
}

function getLightOrDark(color){
 if (typeof color === 'string' || color instanceof String) color = hexToRgb(color);
        var yiq = ((color.r*299)+(color.g*587)+(color.b*114)) / 1000;

        
        return (yiq >= 128) ? 'light' : 'dark';     
}

 function openMailerForm(e){
        // open a modal called '#help-'+elem
        e.preventDefault();
        $("body").addClass('form-toggled');        
        $("#mailerform").css('display', 'block');           
       
    }
function deleteMap(id) {
    // if this map belongs to the person logged in, delete it.
    // $.get('/api/maps/p/clear/'+id);
    $.ajax({
        type: "GET",
        url: "/api/maps/m/clear/" + id,
        success: function(result) {
            $(".map" + id).fadeOut(300, function(){$(".map" + id).remove();});
        },
        error: function(exception) {}
    });
}
